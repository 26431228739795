import * as React from 'react';
import { Grid, Form, useFormId, FormField } from '@programari-limit/base-react';

export const DocumentForm = (props) => {
    const id = useFormId(props);
    return <Form
        resourceName="document"
        titleCreate="Crear document"
        titleUpdate="Modificar document"
        id={id}>
        <FormField name="tipus" />
        <br /><br />
        <FormField name="titol" />
        <br /><br />
        <FormField name="data" />
    </Form>;
}

const Documents = () => {
    const columns = [{
        name: 'titol',
        flex: 3
    }, {
        name: 'data',
        flex: 2
    }];
    const inlineEdit = false;
    const popupEdit = true;
    const gridEditProps = inlineEdit ? {} : (popupEdit ? {
        formPopupComponent: <DocumentForm />,
        formPopupTitleCreate: 'Crear document',
        formPopupTitleUpdate: 'Modificar document',
    } : {
        formRoutePath: './form',
    });
    return <Grid
        resourceName="document"
        title="Documents"
        columns={columns}
        fixedQuery={'tipus==\'TRACTOR\''}
        height={500}
        {...gridEditProps} />;
}

export default Documents;
