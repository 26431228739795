const translationEn = {
    page: {
        notFound: 'Not found',
        dades: {
            title: 'My data',
            update: {
                info: ' To modify these data you have to request it through the contact form.',
                button: 'Request'
            },
            camp: {
                codi: 'Code',
                nom: 'Name',
                nif: 'NIF',
                email: 'E-mail',
                adreca: 'Address',
                codiPostal: 'Postal code',
                poblacio: 'Population',
                telefon: 'Telephone',
            }
        },
        tractors: {
            title: 'Tractors',
            action: {
                download: 'Download'
            }
        },
        cultius: {
            title: 'Crops',
            action: {
                download: 'Download'
            }
        },
        factures: {
            title: 'Invoices',
            action: {
                download: 'Descarregar'
            }
        },
        especejaments: {
            title: 'Parts',
            action: {
                download: 'Descarregar'
            }
        },
        manuals: {
            title: 'Manuals',
            action: {
                download: 'Descarregar'
            }
        },
        dosificacions: {
            title: 'Dosages',
            action: {
                download: 'Descarregar'
            }
        },
        certificats: {
            title: 'Certificates',
            action: {
                download: 'Descarregar'
            }
        },
        garanties: {
            title: 'Warranties',
            action: {
                download: 'Descarregar'
            }
        },
        manteniments: {
            title: 'Manteniments',
            action: {
                download: 'Descarregar'
            }
        },
        contacte: {
            title: 'Contact',
            customer: {
                request: 'Customer data modification request',
            },
            field: {
                subject: 'Subject',
                body: 'Message',
            },
            action: {
                send: 'Send',
                success: 'Message sent successfully',
            }
        },
    }
};

export default translationEn;