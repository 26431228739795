const translationEs = {
    page: {
        notFound: 'No encontrado',
        dades: {
            title: 'Mis datos',
            update: {
                info: 'Para modificar estos datos tendrá que solicitarlo mediante el formulario de contacto.',
                button: 'Solicitar'
            },
            camp: {
                codi: 'Código',
                nom: 'Nombre',
                nif: 'NIF',
                email: 'Correo-e',
                adreca: 'Dirección',
                codiPostal: 'Código postal',
                poblacio: 'Población',
                telefon: 'Teléfono',
            }
        },
        tractors: {
            title: 'Tractores',
            action: {
                download: 'Descargar'
            }
        },
        cultius: {
            title: 'Cultivos',
            action: {
                download: 'Descargar'
            }
        },
        factures: {
            title: 'Facturas',
            action: {
                download: 'Descargar'
            }
        },
        especejaments: {
            title: 'Despieces',
            action: {
                download: 'Descargar'
            }
        },
        manuals: {
            title: 'Manuales',
            action: {
                download: 'Descargar'
            }
        },
        dosificacions: {
            title: 'Dosificaciones',
            action: {
                download: 'Descargar'
            }
        },
        certificats: {
            title: 'Certificados',
            action: {
                download: 'Descargar'
            }
        },
        garanties: {
            title: 'Garantias',
            action: {
                download: 'Descargar'
            }
        },
        manteniments: {
            title: 'Mantenimientos',
            action: {
                download: 'Descargar'
            }
        },
        contacte: {
            title: 'Contacto',
            customer: {
                request: 'Solicitud de modificación de datos del cliente',
            },
            field: {
                subject: 'Asunto',
                body: 'Mensaje',
            },
            action: {
                send: 'Enviar',
                success: 'El mensaje se ha enviado correctamente',
            }
        },
    }
};

export default translationEs;