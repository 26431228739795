import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Grid, Form, FormField } from '@programari-limit/base-react';

export const PressupostForm = () => {
  const { id } = useParams();
  return (
    <Form
      id={id}
      resourceName="fact/pressupost"
      titleCreate="Crear pressupost"
      titleUpdate="Modificar pressupost"
      variant="outlined"
    >
      {/* <FormField name="numero" />
        <br /><br />
        <FormField name="data" />
        <br /><br />
        <FormField name="serieVenda" />
        <br /><br />
        <FormField name="estat" />
        <br /><br />
        <FormField name="dte" />
        <br /><br />
        <FormField name="lliuramentParcial" />
        <br /><br />
        <FormField name="client" />
        <br /><br />
        <FormField name="divisa" />
        <br /><br />
        <FormField name="diaAcceptacio" /> */}
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <FormField name="client" />
        <FormField name="subClient" />
        <FormField name="clientAdresa" />
        <FormField name="divisa" />
        <FormField name="projecte" />
        <FormField name="diaAcceptacio" />
        <FormField name="operari" />
      </Box>
      <hr />
      <p style={{ marginLeft: '10px' }}>1</p>
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <FormField name="telefon" />
        <FormField name="domicili" />
        <FormField name="dte" />
        <FormField name="nomClient" />
        <FormField name="serieVenda" />
        <FormField name="divisa" />
        <FormField name="operari" />
      </Box>
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <FormField name="clientAdresa" />
        <FormField name="classeRetencio" />
        <FormField name="tipusRetencio" />
        <FormField name="pteRetencio" />
        <FormField name="tarifa" />
        <FormField name="codiPostal" />
        <FormField name="tipusComissio" />
      </Box>
      <hr />
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <p style={{ marginLeft: '10px' }}>2</p>
        <FormField name="codiPostal" />
        <FormField name="tarifa" />
        <FormField name="classeRetencio" />
        <FormField name="tipusRetencio" />
        <FormField name="pteRetencio" />
        <FormField name="tipusComissio" />
      </Box>
      <hr />
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <p style={{ marginLeft: '10px' }}>3</p>
        <FormField name="domicili" />
        <FormField name="codiPostal" />
        <FormField name="lat" />
        <FormField name="lon" />
      </Box>
      <hr />
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <Box sx={{ width: '25%', display: 'flex' }}>
          <p style={{ marginLeft: '10px' }}>4</p>
          <FormField name="valorDivisaEuros" />
        </Box>
        <Box sx={{ width: '75%', display: 'flex' }}>
          <p style={{ marginLeft: '10px' }}>5</p>
          <FormField name="tipusFactura" />
          <FormField name="finalFactura" />
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <Box sx={{ width: '50%', display: 'flex', mb: 3 }}>
          <p style={{ marginLeft: '10px' }}>6</p>
          <FormField name="estat" />
        </Box>
        <Box sx={{ width: '50%', display: 'flex', mb: 3 }}>
          <p style={{ marginLeft: '10px' }}>7</p>
          <FormField name="tipusComissio" />
        </Box>
      </Box>
      <hr />
      <Box sx={{ width: '100%', display: 'flex', mb: 3 }}>
        <FormField name="divisa" />
        <FormField name="certificacioOrigen" />
        <FormField name="condicionsPagament" />
        <FormField name="despesesAddicionals" />
        <FormField name="idioma" />
        <FormField name="diaCaducitat" />
        <FormField name="observacions" />
      </Box>
    </Form>
  );
};

const Pressuposts = () => {
  const columns = [
    {
      name: 'serieVenda',
      flex: 2,
    },
    {
      name: 'numero',
      flex: 1,
    },
    {
      name: 'nomClient',
      flex: 3,
    },
    {
      name: 'client',
      flex: 2,
    },
    {
      name: 'versio',
      flex: 1,
    },
    {
      name: 'data',
      flex: 1,
    },
    // {
    //   name: 'divisa',
    //   flex: 2,
    // },
  ];
  const inlineEdit = false;
  const popupEdit = false;
  const gridEditProps = inlineEdit
    ? {}
    : popupEdit
      ? {
        formPopupComponent: <PressupostForm />,
        formPopupTitleCreate: "Crear pressupost",
        formPopupTitleUpdate: "Modificar pressupost",
      }
      : {
        formRoutePath: 'form',
      };
  return (
    <Grid
      resourceName="fact/pressupost"
      title="Pressuposts"
      columns={columns}
      height={600}
      //fixedQuery={'despesesAddicionals==27.76'} // TODO. Poner valor fecha de RangePicker
      {...gridEditProps}
    />
  );
};

export default Pressuposts;
