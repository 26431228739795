import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, useResourceApi, useBaseAppContext } from '@programari-limit/base-react';

const Manteniments = () => {
    const { t } = useTranslation();
    const { showTemporalMessage } = useBaseAppContext();
    const { execDownload: apiExecDownload } = useResourceApi('article');
    const columns = [{
        name: 'data',
        flex: 2
    }, {
        name: 'codi',
        flex: 2
    }, {
        name: 'descripcio',
        flex: 6
    }, {
        name: 'referencia',
        flex: 2
    }, {
        name: 'manteniment',
        flex: 2
    }];
    const onDownloadActionClick = (params) => {
        apiExecDownload(
            'generate_DOWNLOAD',
            {
                id: params.id,
                callbacks: {
                    setError: (error) => {
                        showTemporalMessage?.(error.message, 'error');
                    }
                }
            });
    }
    return <Grid
        resourceName="article"
        title={t('page.manteniments.title')}
        columns={columns}
        fixedQuery={'tipus==\'MANTENIMENT\''}
        formStaticData={{ tipus: 'MANTENIMENT' }}
        defaultSortModel={[{ field: 'data', sort: 'desc' }]}
        rowAdditionalActions={(params) => {
            const adjunt = params.row.adjunt;
            return adjunt && [{
                label: t('page.manteniments.action.download'),
                icon: 'description',
                onClick: onDownloadActionClick,
                showInMenu: false,
            }];
        }}
        height={500} />;
}

export default Manteniments;
