import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useAppMenusContext } from '@programari-limit/base-react';

const CARD_WIDTH = 3;

const HomeCard = (props) => {
    const { title, icon, href } = props;
    return <Card>
        <CardActionArea component={RouterLink} to={'' + href} disableRipple>
            <CardContent>
                <Box sx={{ textAlign: 'center' }}><Icon sx={{ fontSize: 60 }}>{icon}</Icon></Box>
                <Typography variant="h4" sx={{ textAlign: 'center' }}>{title}</Typography>
            </CardContent>
        </CardActionArea>
    </Card>;
}

const HomeGroup = (props) => {
    const { title, icon, childEntries } = props;
    return <Card>
        <CardContent>
            <Typography variant="h4">
                {icon && <Icon fontSize="large" sx={{pt: .7, mr: 1}}>{icon}</Icon>}
                {title}
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {childEntries?.map(entry => {
                    const hasChildren = entry.children?.length;
                    return <Grid key={entry.id} xs={hasChildren ? 12 : CARD_WIDTH}>
                        {hasChildren ? <HomeGroup
                            title={entry.title}
                            icon={entry.icon} /> : <HomeCard
                            title={entry.title}
                            icon={entry.icon}
                            href={entry.resource} />}
                    </Grid>;
                })}
            </Grid>
        </CardContent>
    </Card>;
}

const Home = () => {
    const { menus } = useAppMenusContext();
    const userMenu = menus && menus.find((m) => m.id === ('user'));
    return <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {userMenu?.children?.map(entry => {
            const hasChildren = entry.children?.length;
            return <Grid key={entry.id} xs={hasChildren ? 12 : CARD_WIDTH}>
                {hasChildren ? <HomeGroup
                    title={entry.title}
                    icon={entry.icon}
                    childEntries={entry.children} /> : <HomeCard
                    title={entry.title}
                    icon={entry.icon}
                    href={entry.resource} />}
            </Grid>;
        })}
    </Grid>;
}

export default Home;