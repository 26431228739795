import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';

import { useResourceApi, useBaseAppContext } from '@programari-limit/base-react';

const Contacte = () => {
    const { t } = useTranslation();
    const { showTemporalMessage } = useBaseAppContext();
    const { exec: apiExec } = useResourceApi('client');
    const [searchParams] = useSearchParams();
    const [subject, setSubject] = React.useState();
    const [body, setBody] = React.useState();
    const [sending, setSending] = React.useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    React.useEffect(() => {
        if (searchParams && searchParams.get('dades')) {
            setSubject(t('page.contacte.customer.request'));
        }
    }, [searchParams])
    const sendMissatge = React.useCallback(() => {
        if (subject && subject !== '') {
            setSending(true);
            setShowSuccessMessage(false);
            apiExec(
                'sendMail',
                {
                    data: {
                        subject: subject,
                        body: body
                    },
                    callbacks: {
                        setResponse: () => {
                            setSubject('');
                            setBody('');
                            setShowSuccessMessage(true);
                            setSending(false);
                        },
                        setError: (error) => {
                            showTemporalMessage?.(error.message, 'error');
                            setSending(false);
                        },
                    },
                    refresh: true,
                });
        }
    }, [setSending, apiExec, subject, body, setSubject, setBody, setShowSuccessMessage, showTemporalMessage]);
    return <>
        <Toolbar
            disableGutters
            sx={{
                display: 'flex',
                ml: -3,
                mr: -3,
                mb: 2,
                pl: 3,
                pr: 3,
                backgroundColor: '#efefef'
            }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>{t('page.contacte.title')}</Typography>
        </Toolbar>
        {showSuccessMessage && <Alert
            severity="success"
            sx={{
                "& .MuiAlert-message": {
                    width: "100%"
                }
            }}>
            <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                    {t('page.contacte.action.success')}
                </div>
                <div>
                    <IconButton size="small" onClick={() => setShowSuccessMessage(false)}>
                        <Icon fontSize="small">close</Icon>
                    </IconButton>
                </div>
            </div>
        </Alert>}
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid key="subject" xs={12}>
                <TextField
                    value={subject}
                    label={t('page.contacte.field.subject')}
                    onChange={e => setSubject(e.target.value)}
                    variant="filled"
                    fullWidth />
            </Grid>
            <Grid key="body" xs={12}>
                <TextField
                    label={t('page.contacte.field.body')}
                    value={body}
                    onChange={e => setBody(e.target.value)}
                    variant="filled"
                    fullWidth
                    multiline
                    minRows={5} />
            </Grid>
            <Grid sx={{ flexDirection: 'row-reverse', display: 'flex' }} xs={12}>
                <Button variant="contained" onClick={sendMissatge} disabled={sending}>
                    {sending && <CircularProgress size={14} sx={{ mr: 1 }} />}
                    {t('page.contacte.action.send')}
                </Button>
            </Grid>
        </Grid>
    </>;
}

export default Contacte;