import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BaseApp } from '@programari-limit/base-react';

import logo from './assets/logo.png';
import background from './assets/background2.jpg';

import Home from './pages/Home';
import Idiomes from './pages/Idiomes';
import Pressuposts, { PressupostForm } from './pages/Pressuposts';
import TestArbre from './pages/TestArbre';
import Documents, { DocumentForm } from './pages/Documents';
import Dades from './pages/Dades';
import Tractors from './pages/Tractors';
import Cultius from './pages/Cultius';
import Factures from './pages/Factures';
import Especejaments from './pages/Especejaments';
import Manuals from './pages/Manuals';
import Dosificacions from './pages/Dosificacions';
import Certificats from './pages/Certificats';
import Garanties from './pages/Garanties';
import Manteniments from './pages/Manteniments';
import Contacte from './pages/Contacte';
import NotFoundPage from './pages/404';

const HEADER_COLOR = '#32814B';

const portalclientTheme = createTheme({
    palette: {
        primary: {
            main: HEADER_COLOR,
        },
    },
});

const App = () => {
    const backgroundGradient = 'linear-gradient(to right, ' + HEADER_COLOR + 'FF, ' + HEADER_COLOR + '00),';
    const headerStyle = {
        backgroundImage: backgroundGradient + `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    };
    return <ThemeProvider theme={portalclientTheme}>
        <BaseApp image={logo} headerStyle={headerStyle} name="Portal del client">
            <Routes>
                <Route index element={<Home />} />
                <Route path="dades" element={<Dades />} />
                <Route path="fact">
                    <Route path="idioma" element={<Idiomes />} />
                    <Route path="divisa" element={<TestArbre />} />
                    <Route path="pressupost">
                        <Route index element={<Pressuposts />} />
                        <Route path="form">
                            <Route index element={<PressupostForm />} />
                            <Route path=":id" element={<PressupostForm />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="document">
                    <Route index element={<Documents />} />
                    <Route path="form">
                        <Route index element={<DocumentForm />} />
                        <Route path=":id" element={<DocumentForm />} />
                    </Route>
                </Route>
                <Route path="tractors" element={<Tractors />} />
                <Route path="cultius" element={<Cultius />} />
                <Route path="factures" element={<Factures />} />
                <Route path="especejaments" element={<Especejaments />} />
                <Route path="manuals" element={<Manuals />} />
                <Route path="dosificacions" element={<Dosificacions />} />
                <Route path="certificats" element={<Certificats />} />
                <Route path="garanties" element={<Garanties />} />
                <Route path="manteniments" element={<Manteniments />} />
                <Route path="contacte" element={<Contacte />} />
                <Route path="404" element={<NotFoundPage />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
        </BaseApp>
    </ThemeProvider>;
}

export default App;