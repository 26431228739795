import * as React from 'react';
import { Grid, Form, useFormId, FormField } from '@programari-limit/base-react';

const IdiomaFormContent = () => {
    const id = useFormId();
    return <>
        <FormField name="codi" sx={{mt: 1}}/>
        <br /><br />
        <FormField name="descripcio" />
        <br /><br />
        <FormField name="codiIso" />
    </>;
}

export const IdiomaForm = (props) => {
    return <Form
        resourceName="fact/idioma"
        titleCreate="Crear idioma"
        titleUpdate="Modificar idioma">
        <IdiomaFormContent />
    </Form>;
}

const Idiomes = () => {
    const columns = [{
        name: 'codi',
        flex: 1
    }, {
        name: 'descripcio',
        flex: 2
    }, {
        name: 'codiIso',
        flex: 1
    }];
    const inlineEdit = false;
    const popupEdit = true;
    const formEdit = true;
    const gridEditProps = inlineEdit ? {} : (popupEdit ? {
        formPopupComponent: <IdiomaForm />,
        formPopupTitleCreate: 'Crear idioma',
        formPopupTitleUpdate: 'Modificar idioma',
    } : (formEdit ? {
        formRoutePath: './form',
    } : {
        readOnly: true
    }));
    return <Grid
        title="Idiomes"
        columns={columns}
        resourceName="fact/idioma"
        //selectable
        onSelectionChange={(selection)=> console.log('>>> onSelectionChange', selection)}
        //rowHideEditAction
        height={600}
        {...gridEditProps} />;
}

export default Idiomes;
