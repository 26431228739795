const translationCa = {
    page: {
        notFound: 'No trobat',
        dades: {
            title: 'Les meves dades',
            update: {
                info: 'Per a modificar aquestes dades ho haureu de sol·licitar mitjançant el formulari de contacte.',
                button: 'Sol·licitar'
            },
            camp: {
                codi: 'Codi',
                nom: 'Nom',
                nif: 'NIF',
                email: 'Correu-e',
                adreca: 'Adreça',
                codiPostal: 'Codi postal',
                poblacio: 'Població',
                telefon: 'Telèfon',
            }
        },
        tractors: {
            title: 'Tractors',
            action: {
                download: 'Descarregar'
            }
        },
        cultius: {
            title: 'Cultius',
            action: {
                download: 'Descarregar'
            }
        },
        factures: {
            title: 'Factures',
            action: {
                download: 'Descarregar'
            }
        },
        especejaments: {
            title: 'Especejaments',
            action: {
                download: 'Descarregar'
            }
        },
        manuals: {
            title: 'Manuals',
            action: {
                download: 'Descarregar'
            }
        },
        dosificacions: {
            title: 'Dosificacions',
            action: {
                download: 'Descarregar'
            }
        },
        certificats: {
            title: 'Certificats',
            action: {
                download: 'Descarregar'
            }
        },
        garanties: {
            title: 'Garanties',
            action: {
                download: 'Descarregar'
            }
        },
        manteniments: {
            title: 'Manteniments',
            action: {
                download: 'Descarregar'
            }
        },
        contacte: {
            title: 'Contacte',
            customer: {
                request: 'Sol·licitud de modificació de dades del client',
            },
            field: {
                subject: 'Assumpte',
                body: 'Missatge',
            },
            action: {
                send: 'Enviar',
                success: 'El missatge s\'ha enviat correctament',
            }
        },
    }
};

export default translationCa;