import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Grid, useResourceApi, useBaseAppContext } from '@programari-limit/base-react';

const Factures = () => {
    const { t } = useTranslation();
    const { showTemporalMessage } = useBaseAppContext();
    const { execDownload: apiExecDownload } = useResourceApi('factura');
    const columns = [{
        name: 'data',
        flex: 2
    }, {
        name: 'serie',
        flex: .5
    }, {
        name: 'numero',
        flex: 1
    }, {
        name: 'referencia',
        flex: 4
    }, {
        name: 'pressupostCodi',
        flex: 2
    }, {
        name: 'base',
        type: 'currency',
        currencyCode: 'EUR',
        decimalPlaces: 2,
        flex: 2
    }, {
        name: 'iva',
        type: 'currency',
        currencyCode: 'EUR',
        decimalPlaces: 2,
        flex: 2
    }, {
        name: 'total',
        type: 'currency',
        currencyCode: 'EUR',
        decimalPlaces: 2,
        flex: 2,
        cellClassName: (params) => params.row.pagada ? 'total-pendent' : null,
    }];
    const onDownloadActionClick = (params) => {
        apiExecDownload(
            'generate_DOWNLOAD',
            {
                id: params.id,
                callbacks: {
                    setError: (error) => {
                        showTemporalMessage?.(error.message, 'error');
                    }
                }
            });
    }
    return <Box
        sx={{
            '& .total-pendent': {
                backgroundColor: '#B02421',
                color: 'white'
            },
        }}>
        <Grid
            resourceName="factura"
            title={t('page.factures.title')}
            columns={columns}
            defaultSortModel={[{ field: 'data', sort: 'desc' }]}
            rowAdditionalActions={(params) => {
                const adjunt = params.row.adjunt;
                return adjunt && [{
                    label: t('page.factures.action.download'),
                    icon: 'description',
                    onClick: onDownloadActionClick,
                    showInMenu: false,
                }];
            }}
            height={500} />
    </Box>;
}
export default Factures;
