import * as React from 'react';
import { Grid, Form, useFormId, FormField, useBaseAppContext, useResourceApi, useExcelPaste, ASYNC_STATUS_SUCCESS } from '@programari-limit/base-react';

const LiniaFormContent = () => {
    const id = useFormId();
    return <>
        <FormField name="tipus" />
        <br /><br />
        <FormField name="titol" />
        <br /><br />
        <FormField name="data" />
    </>;
}

export const LiniaForm = (props) => {
    return <Form
        resourceName="document"
        titleCreate="Crear document"
        titleUpdate="Modificar document">
        <LiniaFormContent />
    </Form>;
}

const Documents = () => {
    const { exec: apiCapitolExec } = useResourceApi('fact/pressupostCapitol');
    const { exec: apiPartidaExec } = useResourceApi('fact/pressupostPartida');
    const { showTemporalMessage } = useBaseAppContext();
    const gridApiRef = React.useRef();
    const columns = [{
        name: 'sequencia',
        editable: false,
        flex: 0.5
    }, {
        name: 'article',
        flex: 2
    }, {
        name: 'descripcio',
        flex: 3
    }, {
        name: 'unitats',
        flex: 1
    }, {
        name: 'preu',
        flex: 1
    }, {
        name: 'importe',
        flex: 1
    }];
    const inlineEdit = true;
    const popupEdit = false;
    const formEdit = false;
    const gridEditProps = inlineEdit ? {} : (popupEdit ? {
        formPopupComponent: <LiniaForm />,
        formPopupTitleCreate: 'Crear línia',
        formPopupTitleUpdate: 'Modificar línia',
    } : (formEdit ? {
        formRoutePath: './form',
    } : {
        readOnly: true
    }));
    const treeDataProcessPath = (path) => {
        return path.map((p, i, a) => i < a.length - 1 ? p.descripcio : (a.length > 2 ? p.codi : p.descripcio));
    }
    const treeDataGroupRowDataFromPath = (path) => {
        if (path && path.length > 1) {
            const parentPath = path.slice(0, -1);
            return parentPath.map((p, i, a) => {
                const parentId = p.id;
                const rowData = {
                    id: parentId,
                    path: a.slice(0, i + 1),
                    sequencia: p.sequencia,
                    descripcio: p.descripcio
                };
                //console.log('>>> treeDataGroupRowDataFromPath row', parentId, rowData)
                return [parentId, rowData]
            });
        }
    }
    /*const treeDataRowReorderingGetDragType = (path) => {
        if (path && path.length > 1) {
            const parent = path[path.length - 2];
            return parent.id;
        }
    }
    const treeDataRowReorderingGetDropType = (path) => {
        if (path && path.length > 1) {
            const parent = path[path.length - 2];
            return parent.id;
        }
    }*/
    const treeDataRowReorderingOnStructureOrderChange = (row, change) => {
        const tipus = row?.path?.[row.path.length - 1]?.tipus;
        console.log('>>> treeRowReorderingOnStructureOrderChange', row, tipus, change)
        const apiExec = tipus === 'CAPITOL' ? apiCapitolExec : (tipus === 'PARTIDA' ? apiPartidaExec : null);
        if (row && apiExec) {
            apiExec(
                'patch',
                {
                    id: row.id,
                    data: change,
                    callbacks: {
                        setStatus: (status) => {
                            if (status === ASYNC_STATUS_SUCCESS) {
                                showTemporalMessage?.('Registre reordenat');
                                gridApiRef.current.doRefresh();
                            }
                        },
                        setError: (error) => {
                            showTemporalMessage?.(error.message, 'error');
                        },
                    }
                });
        }
    }
    const onRowAdditionalActionClick = (params) => {
        console.log('>>> onRowAdditionalActionClick', params)
    }
    const rowAdditionalActions = (params) => {
        const isGroupRow = params.row.path.length < 3;
        if (isGroupRow) {
            return [{
                icon: 'edit',
                label: 'Modificar',
                onClick: onRowAdditionalActionClick,
            }, {
                icon: 'delete',
                label: 'Esborrar',
                showInMenu: true,
                onClick: onRowAdditionalActionClick,
            }];
        }
    }
    useExcelPaste((rows) => console.log('pasted rows:', rows));
    const capitolsPartides = true;
    const reorderingAndTreeProps = capitolsPartides ? {
        fixedQuery: 'pressupost.id==\'eyJpZGVudGlmaWNhZG9yQ29kaSI6IkxJTSIsImNvZGkiOjI3NSwiZW1wcmVzYUNvZGkiOiJQUk8yIn0=\'',
        fixedSortModel: [
            { field: 'partida.capitol.sequencia', sort: 'asc' },
            { field: 'partida.sequencia', sort: 'asc' },
            { field: 'sequencia', sort: 'asc' },
        ],
        treeData: true,
        treeDataPathAttribute: 'path',
        treeDataProcessPath: treeDataProcessPath,
        treeDataGroupRowDataFromPath: treeDataGroupRowDataFromPath,
        treeDataGroupColumnProps: {
            headerName: 'Pimpam',
            minWidth: 600,
        },
        treeDataRowReordering: true,
        treeDataRowReorderingSameParentOnly: true,
        treeDataRowReorderingField: 'sequencia',
        //treeDataRowReorderingGetDragType: treeRowReorderingGetDragType,
        //treeDataRowReorderingGetDropType: treeRowReorderingGetDropType,
        treeDataRowReorderingOnStructureOrderChange: treeDataRowReorderingOnStructureOrderChange,
        rowAdditionalActions: rowAdditionalActions,
    } : {
        fixedQuery: 'pressupost.id==\'eyJpZGVudGlmaWNhZG9yQ29kaSI6IkxJTSIsImNvZGkiOjIsImVtcHJlc2FDb2RpIjoiUFJPMiJ9\'',
        fixedSortModel: [
            { field: 'sequencia', sort: 'asc' },
        ],
        rowReordering: true,
        rowReorderingField: 'sequencia',
    }
    return <Grid
        title="Línies de pressupost"
        columns={columns}
        resourceName="fact/pressupostLinia"
        //readOnly
        perspectives={['path']}
        {...reorderingAndTreeProps}
        density="compact"
        selectable
        //height={600}
        autoHeight
        apiRef={gridApiRef}
        /*rowActionsColumnProps={{
            minWidth: 200
        }}*/
        {...gridEditProps} />;
}

export default Documents;
