import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Alert from '@mui/material/Alert';
import { ButtonLink, useResourceApi, useBaseAppContext } from '@programari-limit/base-react';

const ClientInfo = (props) => {
    const { client } = props;
    const { t } = useTranslation();
    return client ? <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid key="codi" xs={6}>
            <TextField label={t('page.dades.camp.codi')} variant="filled" value={client.codi} readOnly fullWidth />
        </Grid>
        <Grid key="nom" xs={8}>
            <TextField label={t('page.dades.camp.nom')} variant="filled" value={client.nom} readOnly fullWidth />
        </Grid>
        <Grid key="nif" xs={4}>
            <TextField label={t('page.dades.camp.nif')} variant="filled" value={client.nif} readOnly fullWidth />
        </Grid>
        <Grid key="telefon" xs={6}>
            <TextField label={t('page.dades.camp.telefon')} variant="filled" value={client.telefon || ""} readOnly fullWidth />
        </Grid>
        <Grid key="email" xs={6}>
            <TextField label={t('page.dades.camp.email')} variant="filled" value={client.email} readOnly fullWidth />
        </Grid>
        <Grid key="poblacio" xs={8}>
            <TextField label={t('page.dades.camp.poblacio')} variant="filled" value={client.poblacio || ""} readOnly fullWidth />
        </Grid>
        <Grid key="codiPostal" xs={4}>
            <TextField label={t('page.dades.camp.codiPostal')} variant="filled" value={client.codiPostal || ""} readOnly fullWidth />
        </Grid>
        <Grid key="adreca" xs={12}>
            <TextField label={t('page.dades.camp.adreca')} variant="filled" value={client.adreca || ""} readOnly fullWidth />
        </Grid>
    </Grid> : null;
}

const Dades = () => {
    const { t } = useTranslation();
    const { showTemporalMessage } = useBaseAppContext();
    const { apiInitialized: apiInitialized, exec: apiExec } = useResourceApi('client');
    const [current, setCurrent] = React.useState();
    const getCurrent = () => {
        apiExec(
            'current',
            {
                callbacks: {
                    setResponse: (response) => {
                        setCurrent(response.data);
                    },
                    setError: (error) => {
                        showTemporalMessage?.(error.message, 'error');
                    },
                },
                refresh: true,
            });
    }
    React.useEffect(() => {
        apiInitialized && getCurrent();
    }, [apiInitialized]);
    return <>
        <Toolbar
            disableGutters
            sx={{
                display: 'flex',
                ml: -3,
                mr: -3,
                mb: 2,
                pl: 3,
                pr: 3,
                backgroundColor: '#efefef'
            }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>{t('page.dades.title')}</Typography>
            <IconButton onClick={getCurrent}>
                <Icon>refresh</Icon>
            </IconButton>
        </Toolbar>
        
        <Alert severity="info" action={<ButtonLink to={{
  pathname: '/contacte',
  search: 'dades=true',
  state: { fromDashboard: true }
 }}
         color="inherit" size="small">{t('page.dades.update.button')}</ButtonLink>}>
            {t('page.dades.update.info')}
        </Alert>
        <ClientInfo client={current} />
    </>
}

export default Dades;
