//import { MENU_SWITCH, MENU_CLOSE } from './actions'

const initialState = {
    //menuOpen: false
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        /*case MENU_SWITCH:
            return {
                ...state,
                menuOpen: !state.menuOpen
            }
        case MENU_CLOSE:
            return {
                ...state,
                menuOpen: false
            }*/
        default:
            return state;
    }
}

export default rootReducer;