import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license-pro';
import caLocale from 'date-fns/locale/ca';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-GB';
import {
    KeycloakAuthProvider,
    BaseAppProvider,
    SessionProvider,
    KettingProvider,
    AppMenusProvider
} from '@programari-limit/base-react';

import store from './redux/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n/i18n';

LicenseInfo.setLicenseKey('6f79fd4869b1f2d8c8a6ef0da7043111T1JERVI6MzM0ODMsRVhQSVJZPTE2Njk4MDc0MzIwMDAsS0VZVkVSU0lPTj0x');

const getAdapterLocale = () => {
    const languageTwoChars = window.navigator.language.substring(0, 2);
    switch (languageTwoChars) {
        case 'ca': return caLocale;
        case 'es': return esLocale;
        default: return enLocale;
    }
}

const contentWithAuth = () => {
    return <KeycloakAuthProvider>
        <React.StrictMode>
            <Provider store={store}>
                <BaseAppProvider>
                    <SessionProvider>
                        <KettingProvider>
                            <AppMenusProvider>
                                <DndProvider backend={HTML5Backend}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getAdapterLocale()}>
                                        <App />
                                    </LocalizationProvider>
                                </DndProvider>
                            </AppMenusProvider>
                        </KettingProvider>
                    </SessionProvider>
                </BaseAppProvider>
            </Provider>
        </React.StrictMode>
    </KeycloakAuthProvider>;
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter>{contentWithAuth()}</BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();